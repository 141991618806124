<template>
  <div class="layoutBorder" >
    <div class="layoutHeader">
      <h4>Мы приняли Вашу заявку</h4>
    </div>
    <div class="layoutBody">
      <div>Наша система начала проверку данных. В течение 15 минут с Вами свяжется наш специалист для дальнейших
        действий.</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "loanBaseAwait"
  }
</script>

<style scoped>

</style>
